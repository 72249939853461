import React from 'react'
import AppLayout from './layout';

function DashboardPage() {
    return (
        <AppLayout />
    )
}

export default DashboardPage;
